body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Custom_Confirm_LightMode{
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: rgb(17, 141, 87);
  background-color: rgba(34, 197, 94, 0.16);
  border: none;
  margin-right: 10px;
  padding: 10px;
  border-radius: 6px;
  font-weight: 700;
  cursor: pointer;
}
.Custom_Cancel_LightMode{
    color: rgb(183, 29, 24);
    background-color: rgba(255, 86, 48, 0.16);
    border: none;
    margin-right: 10px;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
}

.Custom_Cancel_LightMode:hover{
  background-color: rgba(255, 86, 48, 0.4);
}
.Custom_Confirm_LightMode:hover{
  background-color: rgba(34, 197, 94, 0.4);
}/* Стилизация скроллбара для Webkit-браузеров (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Ширина скроллбара */
}

::-webkit-scrollbar-track {
  background: #121A21; /* Цвет дорожки скроллбара, темные тона */
}

::-webkit-scrollbar-thumb {
  background: #555; /* Цвет ползунка, темный оттенок */
  border-radius: 6px; /* Закругление углов ползунка */
}

::-webkit-scrollbar-thumb:hover {
  background: #777; /* Цвет ползунка при наведении, чуть светлее */
}

/* Стилизация скроллбара для Firefox */
* {
  scrollbar-width: thin; /* "auto" или "thin" */
  scrollbar-color: #555 #121A21; /* Цвет ползунка и дорожки */
}