@import '../../styles/vars.scss';


.page{
    @include flex(center,center);
    width: 100%;
    min-height: 100vh;
    background-color:#2B2D3A;
    // background-size: 100% ;
    .leftSide{
        width: calc(100% - 480px);
        min-height: 100vh;
        @include flex(center,center);
        display: none;

        .leftContent{
            text-align: center;
        }
    }
    .rightSide{

        width: 480px;
        // min-height: 100vh;
        height: fit-content;
        padding-top: 15px;
        padding-bottom: 25px;
        border-radius: 20px;
        @include flex(center,center);
        background-color: #333545;
        
        .rightContent{
            width: 352px;
            h3{
                margin-bottom: 20px;
            }
            .loginDesc{
                border-radius: 8px;
                @include flex(center,start);
                width: 320px;
                height: 60px;
                gap: 20px;
                color: #6EDFF5;
                padding: 6px 16px;
                font-size: 14px;
            }
            .input{
                margin-top: 20px;
                @include flex(start,center);
                flex-direction: column;
                font-weight: 500;
                position: relative;
                color: #fff;

                input{
                    width: calc(100% - 30px);
                    margin: 0;
                    padding: 16.5px 14px;
                    border: 1px solid lightgrey;
                    border-radius: 8px;
                    font-family: $font;
                    font-weight: 500;

                }
                span{
                    cursor: pointer;
                    padding: 10px;
                    border-radius: 50%;
                    @include flex(center,center);
                    top: 22px;
                    right: 5px;
                    position: absolute;
                }
                span:hover{
                    background-color: rgba(211, 211, 211,0.3);
                }
                p{
                    position: absolute;
                    color:red;
                    bottom: -34px;
                    left: 4px;
                }
            }
            button{
                margin-top: 30px;
                padding: 8px 16px;
                border-radius: 8px;
                width: 100%;
                box-shadow: none;
                color: rgb(255, 255, 255);
                background-color: rgb(33, 43, 54);
                border: none;
                font-family: $font;
                height: 48px;
                font-size: 15px;
                cursor: pointer;
                transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }
            button:hover{
                background-color: #454f5b;
            }
        }
    }
}