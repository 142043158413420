@import '../../styles/vars.scss';

.page{
    @include flex(center,center);
    width: 100%;
    min-height: 100vh;
    .content{
        width: 70%;
        @include flex(center,center);
        gap: 30px;
        .user{
            width: calc(50% - 30px);
            height: 400px;
            border: 1px solid black;
            border-radius: 12px;
        }
        .admin{
            width: calc(50% - 30px);
            height: 400px;
            border: 1px solid black;
            border-radius: 12px;
        }
    }
}