@import '../../styles/vars.scss';

.tables{
    width: 100%;
    @include flex(center,start);
    .content {
        padding: 30px;
        width: 100%;
        @include flex(start, start);
        flex-direction: column;
        min-height: calc(100vh - 60px);
        gap: 30px;
        transition: all 0.5s ease;
        .topContent{
            width: 100%;
            @include flex(center, space-between);
            h1 {
                margin-top: 0;
                margin-bottom: 0;
            }
            .topContentButtons{
                @include flex(center, end);
                gap: 20px;
                .AddClient{
                    @include flex(center, center);
                    gap: 10px;
                    border:none;
                    font-family: 'Montserrat' , sans-serif;
                    padding: 10px 20px;
                    background-color: #04948C;
                    border-radius: 6px;
                    color:#fff;
                    cursor: pointer;
                    transition: all 0.5s ease-in;
                    text-decoration: none;
                }
            }

        }
        .fields{

            .clientInfo{
                padding: 10px;
                width: 400px;
                border: 1px solid lightgrey;
                border-radius: 8px;
                display: flex;
                align-items: start;
                justify-content: start;
                flex-direction: column;
                gap: 5px;
                h2{
                    margin: 0;
                    margin-bottom: 10px;
                }
                .info{
                    width: 100%;
                    display: flex;
                    align-items: start;
                    justify-content: start;
                    flex-direction: column;
                    label{
                        font-size: 19px;
                    }
                    input {
                        width: calc(100% - 22px);
                        margin-top: 4px;
                        padding: 10px;
                        border-radius: 6px;
                        border: 1px solid lightgray;
                        font-family: $font;
                        font-weight: 500;
                        font-size: 16px;
                    }
                }

            }
        }
    }
}