@import '../../styles/vars.scss';

.tables{
    width: 100%;
    @include flex(center,start);
    .content {
        background-color: #121A21;
        padding: 30px;
        color: #fff;
        width: 100%;
        @include flex(start, start);
        flex-direction: column;
        min-height: calc(100vh - 60px);
        gap: 30px;
        transition: all 0.5s ease;
        .topContent{
            width: 100%;
            @include flex(center, space-between);
            h1 {
                margin-top: 0;
                margin-bottom: 0;
            }
            .topContentButtons{
                @include flex(center, end);
                gap: 20px;
                .Export{
                    @include flex(center, center);
                    gap: 10px;
                    border:none;
                    background-color: #000;
                    color:#fff;
                    font-family: 'Montserrat' , sans-serif;
                    padding: 10px 20px;
                    border-radius: 6px;
                    cursor: pointer;
                    transition: all 0.5s ease-in;
                }

                .Import{
                    @include flex(center, center);
                    gap: 10px;
                    border:none;
                    background-color: #000;
                    color:#fff;
                    font-family: 'Montserrat' , sans-serif;
                    padding: 10px 20px;
                    border-radius: 6px;
                    cursor: pointer;
                    transition: all 0.3s ease-in;
                }
                .AddClient{
                    @include flex(center, center);
                    gap: 10px;
                    border:none;
                    font-family: 'Montserrat' , sans-serif;
                    padding: 10px 20px;
                    background-color: #04948C;
                    border-radius: 6px;
                    color:#fff;
                    cursor: pointer;
                    transition: all 0.5s ease-in;
                    text-decoration: none;
                }
            }

        }
        .DataGrid{
            transition: all 0.5s ease-in;
            width: 89.6vw;
        }
    }
}
input[type=number] {
    -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}