@import './vars.scss';

.navbar{
    height: calc(100vh - 40px);
    padding: 20px;
    border-right: 1px dashed rgba(133, 144, 156, 0.3);
    background-color: #121A21; 
    @include flex(center,space-between);
    flex-direction: column;
    top: 0;
    position: fixed;
    transition: all 0.5s ease;
    
    .leftside{
        width: 100%;
        .logo{
            width: 65px;
            @include flex(center,start);
            flex-direction: column;
        }
        .chooseBrand{
            padding: 10px 15px;
            border-radius: 8px;
            background-color: rgb(33, 43, 54);
            color: #fff;
            font-weight: 500;
            width: 70%;
            text-align: center;
        }
        span{
            text-decoration: none;
            color: rgb(99, 115, 129);
            width: 70%;
            border: none;
            background:none; 
            @include flex(center,start);
            gap: 10px;
            font-weight: 600;
            border-radius: 8px;
            padding: 10px 15px;
            cursor: pointer;
        }
        span:hover{
            background-color: rgba(145, 158, 171, 0.16);
        }
        a:hover{
            background-color: rgba(145, 158, 171, 0.16);
        }
        a:hover svg{
            transition: all 0.5s ease;
            transform: scale(1.03);
        }
        @include flex(center,center);
        flex-direction: column;
        gap: 20px;
        a{
            width: 70%;
            @include flex(center,start);
            gap: 10px;
            color: rgb(99, 115, 129);
            text-decoration: none;
            font-weight: 600;
            transition: all 0.5s ease;
            padding: 10px 15px;
            border-radius: 8px;
        }
        a:hover{
            background-color: rgba(145, 158, 171, 0.16);
        }
    }
    .rightside{
        @include flex(center,center);
        flex-direction: column;
        width: 100%;
        gap: 20px;
        a{
            text-decoration: none;
            color: rgb(99, 115, 129);
            width: 70%;
            border: none;
            background:none; 
            @include flex(center,start);
            gap: 10px;
            font-weight: 600;
            border-radius: 8px;
            padding: 10px 15px;
            // svg{
            //     animation: rotating 12s linear infinite;

            // }
        }
        span{
            text-decoration: none;
            color: rgb(99, 115, 129);
            width: 70%;
            border: none;
            background:none; 
            @include flex(center,start);
            gap: 10px;
            font-weight: 600;
            border-radius: 8px;
            padding: 10px 15px;
            cursor: pointer;
        }
        span:hover{
            background-color: rgba(145, 158, 171, 0.16);
        }
        a:hover{
            background-color: rgba(145, 158, 171, 0.16);
        }
        a:hover svg{
            transition: all 0.5s ease;
            transform: scale(1.03);
        }
    }
    .smallButton{
        width: 17px;
        height: 17px;
        @include flex(center,center);
        position: absolute;
        right: -20px;
        bottom: 3vw;
        background-color: #688CF2;
        padding: 15px;
        border-radius: 50%;
        box-shadow: 0px 0px 11px 0px rgba(92,92,92,0.6);
    }
}
@keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }