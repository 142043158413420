@import '../../styles/vars.scss';

.admin {
    width: 100%;
    @include flex(center, start);
    color:#fff;
    .content {
        padding: 30px;
        width: 100%;
        @include flex(start, start);
        flex-direction: column;
        min-height: calc(100vh - 60px);
        gap: 30px;
        transition: all 0.5s ease;
        h1 {
            margin-top: 0;
            margin-bottom: 0;
        }

        .adds {
            @include flex(none, start);
            gap: 30px;
            flex-wrap: wrap;
            .add {
                padding: 10px;
                width: 400px;
                border: 1px solid lightgrey;
                border-radius: 8px;
                @include flex(start, start);
                flex-direction: column;
                position: relative;
    
                h3 {
                    margin-top: 0;
                }
    
                .addContent {
                    display: flex;
                    gap: 30px;
                    flex-grow: 1;
    
                    .input {
                        @include flex(start, start);
                        flex-direction: column;
    
                        label {
                            font-weight: 500;
                        }
    
                        input {
                            margin-top: 4px;
                            padding: 10px;
                            border-radius: 6px;
                            border: 1px solid lightgray;
                            font-family: $font;
                            font-weight: 500;
                        }
                    }
                    .passGenerator {
                        width: 100%;
                        margin-top: 10px;
                        @include flex(start, start);
                        flex-direction: column;
        
                        span {
                            font-weight: 700;
                        }
        
                        button {
                            margin-top: 10px;
                            padding: 10px;
                            background-color: #04948c;
                            border: none;
                            font-family: $font;
                            font-weight: 700;
                            color: white;
                            cursor: pointer;
                            border-radius: 4px;
                            transition: all 0.5s ease;
                        }
        
                        button:hover {
                            background-color: #10706b;
                        }
                    }
                    .roleSelector {
                        @include flex(start, start);
                        flex-direction: column;
        
                        .roleSelect {
                            width: 180px;
                        }
                    }
                    .leftContent{
                        @include flex(center, space-between);
                        flex-direction: column;
    
                    }
                    .rightContent {
                        @include flex(center, space-between);
                        flex-direction: column;
        
                        .brandSelector {
                            @include flex(start, start);
                            flex-direction: column;
        
                            .roleSelect {
                                width: 180px;
                            }
                        }
        
                        .deskSelector {
                            @include flex(start, start);
                            flex-direction: column;
        
                            .deskSelect {
                                width: 180px;
                            }
                        }
        
                        .createButton {
                            width: 100%;
                            @include flex(end, end);
        
                            .create {
                                margin-top: 10px;
                                padding: 10px;
                                background-color: #04948c;
                                border: none;
                                font-family: $font;
                                font-weight: 700;
                                color: white;
                                cursor: pointer;
                                border-radius: 4px;
                                transition: all 0.5s ease;
                            }
        
                            .create:hover {
                                background-color: #10706b;
                            }
                        }
        
                    }
                }
    
            }
            .addBrand {
                padding: 10px;
                border: 1px solid lightgrey;
                border-radius: 8px;
                @include flex(none, start);
                flex-direction: column;
                position: relative;
    
                h3 {
                    margin-top: 0;
                }
    
                .addContent {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 30px;
                    flex-grow: 1;
                    .input {
                        @include flex(start, start);
                        flex-direction: column;
    
                        label {
                            font-weight: 500;
                        }
    
                        input {
                            margin-top: 4px;
                            padding: 10px;
                            border-radius: 6px;
                            border: 1px solid lightgray;
                            font-family: $font;
                            font-weight: 500;
                        }
                    }
    
                    .createButton {
                        width: 100%;
                        @include flex(end, end);
    
                        .create {
                            margin-top: 10px;
                            padding: 10px;
                            background-color: #04948c;
                            border: none;
                            font-family: $font;
                            font-weight: 700;
                            color: white;
                            cursor: pointer;
                            border-radius: 4px;
                            transition: all 0.5s ease;
                        }
    
                        .create:hover {
                            background-color: #10706b;
                        }
                    }
                }
            }
        }
        .usersGrid{
            width: 76vw;
        }

    }
}